import { Geolocation } from "@ionic-native/geolocation";
import { onUnmounted, onMounted } from "vue";
import { cityInfo } from "@/store.js";
import "leaflet/dist/leaflet.css";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css";
import "leaflet-defaulticon-compatibility";
import L, { marker } from "leaflet";
import "leaflet-geosearch/dist/geosearch.css";
import LocateControl from "leaflet.locatecontrol";
import "leaflet-bing-layer";
import "leaflet-search";
import "leaflet-minimap";
import "leaflet.markercluster";
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet/dist/leaflet.css';

import { useRouter } from "vue-router";
import info from '@/pt.json';

export default function useΜap(container) {
  const router = useRouter();

  let map;

  onMounted(() =>
    setTimeout(() => {

      const OSM = L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
      });

      const GMapsTerrain = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      const GMapasHybrid = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );
      const GMapsSat = L.tileLayer(
        "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
        {
          maxZoom: 20,
          subdomains: ["mt0", "mt1", "mt2", "mt3"],
        }
      );

      const bingkey =
        "Ari-Dh9jtY9399o9wPTyqGLQW2GVL_YQHsykPYfwb-2ySzot-iEeG_vS79eemkE5";
      const BMaps = L.tileLayer.bing(bingkey);

      // create map with default layer
      map = L.map(container, { zoomControl: false, layers: [OSM] }).setView(
        [41.14, -8.61],
        8
      );

      const here = map.getCenter();
      console.log(here.lat, here.lng);

      // const { name } = info;
      // console.log(info[0].city);
      // info.forEach(x => { console.log(x.city, x.id); });
      // let min = 1000000.0;
      // const city = "";

      new L.Control.Zoom({ position: "topleft" }).addTo(map);

      L.control
        .layers(
          {
            "Open Street Maps": OSM,
            "Google Maps Terrain": GMapsTerrain,
            "Google Maps Hybrid": GMapasHybrid,
            "Google Maps Satellite": GMapsSat,
            "Bing Maps": BMaps,
          },
          null,
          { position: "bottomleft" }
          // { collapsed: true }
        )
        .addTo(map);

      // const markersLayer = new L.LayerGroup();
      const markersLayer = new L.MarkerClusterGroup();

      // map.addLayer(markersLayer);

      const osm2 = new L.TileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        minZoom: 0, maxZoom: 13, attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      });
      const miniMap = new L.Control.MiniMap(osm2, {
        toggleDisplay: true, autoToggleDisplay: true, position: "bottomleft",
        collapsedWidth: 32,
        collapsedHeight: 28,
      }).addTo(map);

      //Pan to user current location
      new L.control.locate({
        position: "topleft",
      }).addTo(map);

      //clustering data
      // const markerClusters = L.markerClusterGroup({ showCoverageOnHover: true, 
      //   zoomToBoundsOnClick: true, 
      //   spiderfyOnMaxZoom: true, 
      //   removeOutsideVisibleBounds: true, 
      //   spiderLegPolylineOptions: { weight: 1.5, color: '#222', opacity: 0.5 } });

      // populate map
      info.forEach(x => {
        const title = x.city,
          latit = x.lat,
          long = x.lng,
          id = x.id,
          marker = new L.Marker(
            new L.latLng(latit, long),
            { title: title, opacity: 1 }
          );
        // markerClusters.addLayer(marker);
        // map.addLayer(markerClusters);

        // console.log(loc[0]);
        const element = document.createElement("div");
        const text = document.createElement("span");
        const link = document.createElement("a");

        text.textContent = title + ": ";
        link.textContent = "See Climatic Information";
        link.setAttribute("href", "tab3");
        link.addEventListener("click", (e) => {
          e.preventDefault(); // prevents browser from leaving page
          e.stopPropagation(); // makes event stop here
          // updates the store
          cityInfo.title = title;
          cityInfo.id = id;
          // load new page
          router.push("tab3");
        });
        element.appendChild(text);
        element.appendChild(link);

        marker.bindPopup(element);
        markersLayer.addLayer(marker);
      });
      map.addLayer(markersLayer);


      const controlSearch = new L.Control.Search({
        position: "topleft",
        layer: markersLayer,
        initial: false,
        zoom: 12,
        autoCollapse: true,
        textPlaceholder: "Search Location",
        // marker: {
        //   circle: {
        //     radius: 20,
        //     color: '#0a0',
        //     opacity: 1
        //   }
        // }
        //this marker overrides marker that is already there
        // marker: { title: "teste" },

      });

      map.addControl(controlSearch);



    }, 100)
  );
  const geo = Geolocation;
  let watch;
  onUnmounted(() => {
    if (watch) watch.unsubscribe();
    map.remove();
  });
  return {
    setView(center, zoom) {
      map.setView(center, zoom);
    },
    async updateView(zoom = 13) {
      const resp = await geo.getCurrentPosition();
      const { latitude, longitude } = resp.coords;
      map.setView([latitude, longitude], zoom);
    },

    liveUpdateView(zoom = 13, eCallback) {
      watch = geo.watchPosition();
      watch.subscribe((data) => {
        if (data.error) return eCallback(data.error);
        const { latitude, longitude } = data.coords;
        map.setView([latitude, longitude], zoom);
      });
      return () => watch.unsubscribe();
    },
  };
}
