<template>
  <div id="mapContainer"></div>
</template>

<script>
import { defineComponent } from "vue";

import useMap from "./useMap.js";

export default defineComponent({
  setup() {
    const { updateView } = useMap("mapContainer");
    setTimeout(() => updateView().catch(console.error), 1000);
  },
});
</script>

<style scoped>
#mapContainer {
  width: 100vw;
  height: 100vh;
}
</style>

<style>
/* ZOOM */
.leaflet-top .leaflet-control-zoom {
  top: 33%;
  transform: translateY(-50%);
}
.leaflet-control-zoom,
.leaflet-touch .leaflet-control-zoom {
  border-radius: 4px;
}
.leaflet-control-zoom a,
.leaflet-touch .leaflet-control-zoom a,
.leaflet-bar a,
.leaflet-touch .leaflet-bar a {
  background-color: rgb(255, 255, 255);
  width: 32px;
  height: 28px;
}
.leaflet-touch .leaflet-control-zoom-out {
  line-height: 24px;
}
.leaflet-control-zoom a:hover,
.leaflet-touch .leaflet-control-zoom a:hover,
.leaflet-bar a:hover,
.leaflet-touch .leaflet-bar a:hover {
  background-color: rgba(255, 255, 255, 0);
  width: 32px;
  height: 28px;
}

.leaflet-touch .leaflet-bar a:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.leaflet-touch .leaflet-bar a:last-child {
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: none;
}
.fa-map-marker.fa {
  height: 28px;
  width: 32px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  background-image: url("../../public/locate-outline.svg");
  display: block;
}
.leaflet-top {
  bottom: 0;
}
/* CONTROLS */
.leaflet-bottom .leaflet-control,
.leaflet-left .leaflet-control {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  border: 2px solid #47b6ad;
  border-radius: 6px;
}
.leaflet-left.leaflet-top .leaflet-control,
.leaflet-touch .leaflet-left .leaflet-control {
  margin-left: 8px;
  margin-top: 8px;
  margin-bottom: 0;
}
.leaflet-left.leaflet-bottom .leaflet-control {
  margin-left: 8px;
  margin-bottom: 8px;
  margin-top: 0;
}

/* .leaflet-bottom {
  top: 0;
} */

.leaflet-top .leaflet-control-locate {
  top: 15%;
  transform: translateY(-50%);
}
/* .leaflet-top .leaflet-control-layers {
  top: 80%;
  transform: translateY(-50%);
} */
.leaflet-control-layers {
  bottom: 25rem;
}
/* .leaflet-bottom .leaflet-control-layers {
  bottom: 100%;
  transform: translateY(-50%);
} */
.leaflet-bottom .leaflet-control {
  margin-bottom: 50px;
}

.leaflet-top .leaflet-control-geosearch {
  top: 30%;
  transform: translateY(-50%);
}
.leaflet-top .leaflet-control-geosearch .input-glass {
  top: 10%;
  height: 30px;
  transform: translateY(-50%);
}

.leaflet-control-geosearch form input {
  height: 30px;
}

/* .leaflet-top .leaflet-control .leaflet-minimap {
  top: 70%;
} */
/* .leaflet-left .leaflet-control-scale {
  display: inline-block;
  float: none;
} */
</style>
